@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  font-family: Poppins;
  box-sizing: border-box;
}

.progressBar {
  height: 50px;
  background-color: rgb(1, 208, 80);
  border-radius: 2px;
  transition: width 2s;
}

input {
  text-align: center;
}

td,
th {
  padding: 1vw;
  font-size: 1vw;
}

tr:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}

th:nth-child(even),
td:nth-child(even) {
  background-color: rgba(150, 212, 212, 0.4);
}

#incorrect {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

#correct {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes shake {
  0% {
    transform: translate(-49%, -50%) rotate(0deg);
  }
  10% {
    transform: translate(-50%, -49%) rotate(-1deg);
  }
  20% {
    transform: translate(-51%, -48%) rotate(1deg);
  }
  30% {
    transform: translate(-52%, -49%) rotate(0deg);
  }
  40% {
    transform: translate(-51%, -50%) rotate(1deg);
  }
  50% {
    transform: translate(-50%, -49%) rotate(-1deg);
  }
  60% {
    transform: translate(-49%, -48%) rotate(0deg);
  }
  70% {
    transform: translate(-50%, -49%) rotate(-1deg);
  }
  80% {
    transform: translate(-51%, -50%) rotate(1deg);
  }
  90% {
    transform: translate(-52%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-51%, -49%) rotate(-1deg);
  }
}

/*

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
